/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/app.sass';
import './src/fonts.css';

export const onClientEntry = ( ) => {
  if ( process.env.NODE_ENV !== 'production' )
    return;
  if ( window.location.hostname !== 'resoluteweb.com' )
    window.location.hostname = 'resoluteweb.com';
};