import { createMuiTheme } from '@material-ui/core/styles';

const t = createMuiTheme();

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2ca895',
    },
  },
  typography: {
    // Don't use MUI font family, allow Typography.js to apply
    fontFamily: null,
    fontWeightLight: null,
    fontWeightRegular: null,
    fontWeightMedium: null,
    fontWeightBold: null,
    h2: {
      fontSize: '3.75rem',
      [ t.breakpoints.down( 800 ) ]: {
        fontSize: '2.25rem',
      },
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        '& .MuiButton-label': {
          color: '#fff',
        },
      },
    },
    MuiListItem: {
      root: {
        margin: 0,
        paddingBottom: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 34,
      },
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        paddingBottom: '0.1875rem',
      },
    },
  },
});

export default theme;
